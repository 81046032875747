const reasonApi = {
    // 退货原因列表
    reasonList: '/setting/order/reason/list',
    //删除退货列表
    reasonDel: '/setting/order/reason/delete',
    //新建退货原因
    reasonAdd: '/setting/order/reason/add',
    //编辑退货原理
    reasonEdit: '/setting/order/reason/edit',

}


export default reasonApi