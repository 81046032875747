import request from "@/utils/request";
import reasonApi from '@/const/apiUrl/reason';
import method from "@/const/request/requestMethod";

//退货原因列表
export function reasonList(params) {
    return request({
        url: reasonApi.reasonList,
        method: method.GET,
        data: params
    })
}
//删除退货列表
export function reasonDel(params) {
    return request({
        url: reasonApi.reasonDel,
        method: method.GET,
        data: params
    })
}
//新建退货原因
export function reasonAdd(params) {
    return request({
        url: reasonApi.reasonAdd,
        method: method.POST,
        data: params
    })
}
//编辑退货原理
export function reasonEdit(params) {
    return request({
        url: reasonApi.reasonEdit,
        method: method.POST,
        data: params
    })
}
