<template>
  <el-card class="body" style="margin-top: 10px;">
    <div class="itemPut">
      <div style="margin: 20px"></div>
      <el-form
        :model="reasonForm"
        label-width="150px"
        label-position="right"
        :rules="rules"
        ref="inserform"
      >
        <el-form-item label="退货原因：" prop="reason">
          <el-input v-model.trim="reasonForm.reason"></el-input>
        </el-form-item>
        <el-form-item label="取消类型：" prop="type">
          <el-select placeholder="请选择" v-model="reasonForm.type">
            <el-option
              v-for="(v, k) in source"
              :key="k"
              :label="v.name"
              :value="v.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" v-model.trim="reasonForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <router-link to="/setting/order/reason/list">
          <el-button>取 消</el-button>
        </router-link>
        <el-button
          type="primary"
          style="margin-left: 50px"
          @click="reasonAdd('inserform')"
          >提 交</el-button
        >
      </div>
    </div>
  </el-card>
</template>
<script>
import { reasonAdd } from "@/api/reason";
export default {
  data() {
    return {
      source: [
        { id: 1, name: "取消" },
        { id: 2, name: "退货" },
        { id: 3, name: "退款" },
      ],
      reasonForm: {},
      rules: {
        reason: [
          { required: true, message: "请填写退后原因", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        remark: [{ required: true, message: "请填写备注", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    // 新建退换货原因列表
    reasonAdd(inserform) {
      let self = this;
      self.$refs[inserform].validate((res) => {
        if (res) {
          reasonAdd(self.reasonForm).then(() => {
            this.$router.push("/setting/order/reason/list");
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/overall.less";
</style>